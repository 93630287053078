import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"

const PressWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 7rem 9rem 9rem;
  flex-wrap: wrap;
  ${breakpoint("mobile")`
  padding: 3rem 3rem 9rem;

  `}
  ${breakpoint("tablet")`
  padding: 5rem 7rem 9rem;

  `}
  ${breakpoint("desktop")`
  padding: 7rem 9rem 9rem;

  `}
`
const PressEntry = styled.a`
    width: 25%;
  ${breakpoint("mobile")`
  width: 50%;

  `}
  ${breakpoint("tablet")`
  width: 33.333333%;

  `}
  ${breakpoint("desktop")`
  width: 25%;

  `}

  &:hover {
    filter: invert(100%);
  }
`

const PressEntrySquare = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 100%;
  overflow: hidden;

  img {
    width: 80%;
  }
`

const Press = () => {
  const data = useStaticQuery(graphql`
    query PressQuery {
      press: wordpressPage(slug: { eq: "press" }) {
        title
        acf {
          press_entries {
            url_or_file
            url
            file {
              url {
                localFile {
                  publicURL
                }
              }
            }
            background_color
            logo {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <PressWrapper>
        {data.press.acf.press_entries.map((e, i) => {
          return (
            <PressEntry
              href={
                e.url_or_file === "URL" ? e.url : e.file.url.localFile.publicURL
              }
              target="_blank"
            >
              <div
                style={{
                  width: "100%",
                  height: "0",
                  paddingBottom: "100%",
                  position: "relative",
                }}
              >
                <PressEntrySquare
                  style={{
                    backgroundColor: e.background_color
                      ? e.background_color
                      : "white",
                  }}
                >
                  <img src={e.logo.localFile.publicURL} alt="Press" />
                </PressEntrySquare>
              </div>
            </PressEntry>
          )
        })}
      </PressWrapper>
    </>
  )
}

export default Press
