import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Press from "../components/Press"

const PressPage = () => (
  <Layout page="press">
    <SEO title="Press" />
    <Press></Press>
  </Layout>
)

export default PressPage
